




import _ from 'lodash';
import {Component, Vue} from 'vue-property-decorator';
import AuthService from '@/services/AuthService';
import {Action, State} from 'vuex-class';
import Util from '@/utils/Util';
import * as Sentry from '@sentry/browser';
import SentryService from '@/services/SentryService';
import config from '@/config';
import {APIResource} from '@/utils/APIResources';

@Component({
  name: 'Renewal',
})
export default class Renewal extends Vue {
  @State private app: any;
  @Action('app/setTargetSegment') private setTargetSegment: any;
  @Action('app/setClientId') private setClientId: any;
  @Action('app/setProposalNumber') private setProposalNumber: any;
  @Action('app/reset') private reset: any;
  @Action('app/setValue') private setValue: any;
  @Action('auth/cmsAuthentication') private cmsAuthentication: any;
  @Action('app/resetRenewalResponse') private resetRenewalResponse: any;
  @Action('app/getQuotes') private getQuotes: any;

  private created() {
    this.setValue({code: 'dirty', value: false});
    this.setValue({code: 'loadingText', value: ''});
    this.setValue({code: 'isRenewal', value: true});
    this.setValue({code: 'isSavedProposal', value: false});
    this.setValue({code: 'originUrl', value: window.location.href});
    const proposalId = _.get(this.$route, 'query.proposalId', '');
    const targetSegment = sessionStorage.getItem('targetSegment') || '';
    if (!this.app.targetSegment || this.app.proposalId !== proposalId) {
      this.reset();
      this.setValue({code: 'isRenewal', value: true});
      this.setValue({code: 'originUrl', value: window.location.href});
      this.setTargetSegment({targetSegment});
      const token = _.get(this.$route, 'query.token', '');
      const clientId = _.get(this.$route, 'query.clientId', '');
      this.setClientId(clientId);
      this.setProposalNumber({proposalId});
      Sentry.configureScope((scope) => {
        scope.setTag('journeytype', 'RENEWAL');
      });
      if (!clientId || clientId === 'xxxxxxxx' || !token) {
        window.location.href = '/coverhub-portal/error';
      }
      const ts = targetSegment + (sessionStorage.subSegment ? '-' + sessionStorage.subSegment : '');
      AuthService.authLogin(token, clientId, 'renewal')
          .then((result: any) => {
            localStorage.removeItem('guest-token-' + config.environment + '-' + ts);
            sessionStorage.setItem('login-token-' + config.environment + '-' + ts, result.data.data.token);
            APIResource.setUser();
            window.location.reload();
          })
          .catch((e: any) => {
            console.error(e);
            window.location.href = `/coverhub-portal/auth/${targetSegment}/error?error=${e.message}`;
          });
    } else {
      this.cmsAuthentication({targetSegment}).then(async (response) => {
        await this.resetRenewalResponse();
        await this.getQuotes(proposalId).catch((e: any) => console.error(e));
        const name = response.renewalSkipCatalogue ? 'portal' : 'landing';
        const query = response.renewalSkipCatalogue ? {} : { renewal: 'true'};
        this.$router.replace({ name, params: { targetSegment: this.app.targetSegment }, query });
      }).catch((e) => {
        this.$router.replace({ name: 'landing', params: { targetSegment: this.app.targetSegment }, query: {} });
      });
    }

    Util.gaLogPageView(this, `/renewal+${this.app.targetSegment}`);
  }
}
